//@const {string} STORAGE_KEY Key for storing selected language in localStorage.
const STORAGE_KEY = "currentLang";

//Initializes the page when the window loads. Sets up language localization and form submission.
window.addEventListener("load", function () {
  const savedLang = localStorage.getItem(STORAGE_KEY) || "ua";

  // const btnTextRef = document.querySelector(".selectlang-text");
  const selectlangContainerRef = document.getElementById("selectlangContainer");
  const langBtnContainerRef = document.getElementById("langCheck");
  const dropdownImgRef = document.querySelector(".dropdownImg");
  const btnUaModalRef = document.querySelector('[data-btn="ua"]');
  const btnEnModalRef = document.querySelector('[data-btn="en"]');
  const form = document.getElementById("forma");

  // Add event listeners for language buttons
  btnUaModalRef.addEventListener("click", changeLanguageHandler);
  btnEnModalRef.addEventListener("click", changeLanguageHandler);

  // If there is a saved language, localize the page
  if (savedLang) {
    updateLanguageUI(savedLang);
    updateButtons(savedLang);
    localizeElements(savedLang);
  }

  // Toggle dropdown on language container click
  selectlangContainerRef.addEventListener("click", () => {
    langBtnContainerRef.classList.toggle("selectCheck-active");
    dropdownImgRef.classList.toggle("dropdownActive");
  });

  // Form submission handler
  form.addEventListener("submit", function (e) {
    sendData(formatFormData($("#forma").serializeArray()));
    e.preventDefault();
  });
});

//Language change handler. @param {Event} e The click event.
function changeLanguageHandler(e) {
  if (e.target.dataset.btn) {
    const currentLang = e.target.dataset.btn;
    localizeElements(currentLang);
    updateLanguageUI(currentLang);
    updateButtons(currentLang);
    localStorage.setItem(STORAGE_KEY, currentLang);
  }
}

//Change button icons on language switch
function updateButtons(currentLang) {
  const buttons = document.querySelectorAll(".select-btn");

  buttons.forEach((button) => {
    const img = button.querySelector("img");
    const isActive = button.dataset.btn === currentLang;
    img.style = isActive ? "opacity:1;" : "opacity:0;";
  });
}

//Updates the text of the language button. @param {string} lang The current language
function updateLanguageUI(lang) {
  const btnTextRef = document.querySelector(".selectlang-text");
  btnTextRef.innerHTML = lang;
}

//Localizes all elements that have data-lang attribute. @param {string} lang The current language to apply
function localizeElements(lang) {
  const elements = document.querySelectorAll("[data-lang]");
  const localization = localizationData[lang] || {};

  elements.forEach((element) => {
    const langKey = element.getAttribute("data-lang");
    const langValue = getValueByPath(localization, langKey);

    if (langValue !== undefined) {
      element.innerHTML = langValue;
    }
  });
}

//Formats the form data for sending. @param {Array} data The serialized form data. @return {string} Formatted message string.
function formatFormData(data) {
  return data.map((el) => `${el.name}: ${el.value}`).join("\\n");
}

//??
function getKey() {
  const date = new Date();
  const key = `${date.getUTCDate().toString().padStart(2, "0")}/${(
    date.getUTCMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getUTCFullYear()}`;
  return MD5(key + "==|(&()9*9$#%9*9$#*@!G$H$)(99s*fd12z*xM2*3|==");
}

//Sends form data to the API. @param {string} message The formatted message to be sent.
function sendData(message) {
  const settings = {
    url: "https://robot.ta-da.ua/maintenanceRequestsApi/add",
    method: "POST",
    timeout: 0,
    headers: {
      Authorization: "Key " + getKey(),
    },
    data: `{\r\n  "type": 2,\r\n   "text": "${message}"\n}`,
  };
  $.ajax(settings)
    .done(function () {
      alert("Інформацію надіслано, дякуємо!");
      form.reset();
    })
    .fail(function () {
      alert("Сталася помилка при відправці даних.");
    });
}

//Recursively retrieves a value from an object using a dot-notation path.@param {Object} obj @param {string} path
function getValueByPath(obj, path) {
  const keys = path.split(".");
  return keys.reduce((acc, key) => (acc ? acc[key] : undefined), obj);
}

//Get dynamik info in the page the page with dynamic info. @param {Array} shopList The list of shops to process.
function transformPage(shopList) {
  const dateOfBirthTaDa = new Date("2000-10-10");
  const today = new Date();
  const age = Math.floor(
    (today - dateOfBirthTaDa) / (1000 * 60 * 60 * 24 * 365.25)
  );

  const filteredShops = shopList.filter((el) => el.status === 1);
  const numberOfShops = filteredShops.length;
  const numberOfCities = new Set(filteredShops.map((el) => el.city)).size;

  // Update dynamic info in the page
  $("#numberOfShops").html(numberOfShops);
  $("#numberOfShops2").html(numberOfShops);
  $("#numberOfCity").html(numberOfCities);
  $("#numberOfCity2").html(numberOfCities);
  $("#yearsOnMarket").html(age);
  $("#year").html(today.getFullYear());
}

//Generates SHA-256 key based on the current date. @return {string} SHA-256 hash key.
function getKeySHA256() {
  const date = new Date();
  const key = `${date.getUTCDate().toString().padStart(2, "0")}/${(
    date.getUTCMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getUTCFullYear()}`;
  return sha256(key + "wTH31@6Gl3)-bD(YSF8+y1=9kIpMO0d&xAEd)hrU");
}

//Fetches data from the API and processes the shop list.
async function getData() {
  const key = await getKeySHA256();

  const settings = {
    url: "https://api.ta-da.net.ua/v1/mobile/shops.list",
    method: "GET",
    timeout: 0,
    headers: {
      Authorization: "Key " + key,
    },
  };

  $.ajax(settings)
    .done(function (data) {
      transformPage(data);
    })
    .fail(function () {
      alert("Сталася помилка при отриманні даних.");
    });
}

// Load data
getData();

// Localization data for ua and en.@type {Object}
const localizationData = {
  ua: {
    header: {
      main: "Головна",
      aboutUs: "Про нас",
      whyWe: "Чому ми",
      franchise: "Про франшизу",
      contacts: "Контакти",
    },
    hero: {
      subtitle: "Товари для дому. Щодня!",
      seeMore: "Детальніше",
    },
    aboutUs: {
      title: "Про нас",
      companyInfo: `Мережа супермаркетів ТА-DA! була заснована у 2000 році. На початку компанія спеціалізувалася на оптовій торгівлі. З 2019 року вона почала розвивати власну мережу супермаркетів. Станом на `,
      companyInfo2: ",рік мережа налічує  ",
      companyInfo3: " супермаркетів у ",
      companyInfo4:
        " містах України, які обслуговують понад 450,000 покупців щомісяця. Площа магазинів варіюється від 600 м².",
      description:
        'Місія компанії полягає в створенні супермаркетів, що полегшують життя споживачів, а її гасло звучить: "TA-DA! Товари для дому. Щодня".',
      description1:
        "Компанія активно підтримує спорт і має чітку соціальну позицію, спрямовану на розвиток суспільства. Також вона розвиває корпоративну культуру, засновану на довірі, відповідальності та відкритості у спілкуванні.",
      description2:
        "Мережа супермаркетів ТА-DA! пропонує широкий асортимент товарів для повсякденного вжитку, іграшок, товарів для активного відпочинку, риболовлі, садівництва, канцелярії та багато іншого.",
      description3:
        "Супермаркети ТА-DA! відомі своїми помірними цінами та регулярними акціями, що робить їх привабливими для покупців. Мережа також підтримує місцевих виробників, пропонуючи товари українського виробництва.",
      description4:
        "З 2023 року мережа ТА-DA! активно займається зовнішньоекономічною діяльністю, щоб забезпечити українських споживачів якісними товарами за доступними цінами, цікавими новинками та широким асортиментом.",
    },
    whyWe: {
      years: "роки",
      yearsOnMarket: "Успішно працюємо на ринку",
      cities: "міст",
      andMore: "І з кожним роком ще більше",
      markets: "супермаркетів",
      marketsInUa: "По всій Україні",
      clients: "клієнтів",
      perMonth: "Щомісяця!",
      buyingOnline:
        "Широкий асортимент товарів і доступні ціни не тільки офлайн, але і онлайн!",
      goToSite: "Повернутись на головний сайт",
    },
    franchise: {
      aboutFranchise: "Про Франшизу",
      advantages: "Переваги франшизи",
      advantParah:
        "Широкий, різноманітний і затребуваний асортимент господарських товарів. Весь асортимент ретельно підібраний по виробникам і якості. У маркеті можна придбати наступні групи товарів: побутова хімія, косметика, будівельна хімія, посуд для сервірування і приготування, засоби для прибирання, дрібна побутова техніка, текстиль, дитячі товари, садовий інвентар, насіння, добрива та багато іншого.",
      financials: "Фінансові параметри",
      financialParah:
        "Паушальний внесок залежить від площі супермаркету, в середньому становить 55 $/1м². Бюджет відкриття одного об'єкта від 80 000$. Щомісячний роялті починаючи з другого місяця, обговорюється індивідуально. Очікуваний термін окупності вкладень від 20 міс. Договір складається на термін від 3-х років з можливістю пролонгації. При порушенні умов договору франшиза може бути деактивована.",
    },
    collaboration: {
      header: "СПІВПРАЦЯ",
      description1:
        "Співпраця з українськими та міжнародними виробниками, постачальниками та партнерами є одним із ключових елементів стратегії розвитку мережі супермаркетів ТА-DA!. Компанія прагне забезпечити своїх покупців якісними товарами за доступними цінами, орієнтуючись на надійність, довгострокові партнерські відносини та інновації.",
      header1: "  Співпраця з українськими виробниками та постачальниками",
      localBiznes:
        "ТА-DA! приділяє велику увагу співпраці з українськими виробниками, підтримуючи локальний бізнес і сприяючи розвитку національної економіки. Основний акцент робиться на:",
      localBiznesDesc1: ` <b>Продукти місцевого виробництва:</b> Мережа активно співпрацює з
              українськими виробниками продуктів харчування, одягу, товарів для
              дому та іншої продукції, допомагаючи їм виходити на
              загальнонаціональний ринок. Це дозволяє забезпечити споживачів
              свіжою та якісною продукцією за вигідними цінами.`,
      localBiznesDesc2: `<b>Підтримка малого та середнього бізнесу:</b> ТА-DA! співпрацює
              як з великими національними компаніями, так і з малими та
              середніми виробниками, пропонуючи їм можливість масштабувати свій
              бізнес і збільшити обсяги продажів. Це сприяє розвитку
              підприємницької діяльності в Україні.`,
      localBiznesDesc3: ` <b>Локальні бренди та екологічність:</b> Компанія активно
              підтримує українські бренди, що орієнтуються на виробництво
              екологічної та органічної продукції. Це дає можливість споживачам
              вибирати продукцію, що відповідає сучасним вимогам екологічної
              безпеки та сталого розвитку.`,
      innovation:
        "ТА-DA! також будує довірливі відносини з українськими постачальниками, забезпечуючи стабільні обсяги замовлень і прозорі умови співпраці. Основні критерії вибору постачальників — якість продукції, своєчасність поставок, конкурентні ціни та готовність до впровадження інновацій.",
      internationalHeader:
        "Співпраця з міжнародними виробниками та постачальниками",
      internationalDesc1:
        "ТА-DA! прагне забезпечити своїх покупців різноманітними та якісними товарами, співпрацюючи з міжнародними виробниками та постачальниками з різних країн. Завдяки міжнародній співпраці мережа може запропонувати унікальні товари, що не мають аналогів на українському ринку, а також впроваджувати найкращі світові практики у сфері роздрібної торгівлі.",
      internationalHeader1: "Основними напрямками міжнародної співпраці є:",
      internationalSides1: `<b>Імпорт якісної продукції:</b> ТА-DA! співпрацює з іноземними
              виробниками іграшок, посуду, товарів для дому та інших категорій,
              щоб забезпечити покупцям широкий вибір високоякісної продукції за
              конкурентними цінами. Важливу роль відіграє ретельний відбір
              партнерів, які дотримуються міжнародних стандартів якості та
              безпеки.`,
      internationalSides2: ` <b>Логістика та технології:</b> ТА-DA! співпрацює з іноземними
              виробниками іграшок, посуду, товарів для дому та інших категорій,
              щоб забезпечити покупцям широкий вибір високоякісної продукції за
              конкурентними цінами. Важливу роль відіграє ретельний відбір
              партнерів, які дотримуються міжнародних стандартів якості та
              безпеки.`,
      selectionHeader: "Основні критерії відбору товарів для нашої мережі:",
      selectionDesc1:
        " Продукт відповідає концепції компанії та має конкурентоспроможну ціну.",
      selectionDesc2: "Продукція користується попитом серед покупців.",
      selectionDesc3:
        "Термін придатності товару складає понад 90 календарних днів без спеціальних вимог до температури зберігання.",
      selectionDesc4:
        "Товар має необхідні ліцензії та сертифікати для використання торгової марки.",
      goodsHeader: "Нас цікавлять такі групи товарів:",
      good1: "Дитячі іграшки та розвиваючі ігри",
      good2: "Господарські товари",
      good3: "Кухонне приладдя",
      good4: "Посуд",
      good5: "Канцелярське приладдя",
      good6: "Одяг",
      good7: "Сумки, гаманці, косметика",
      good8: "Прикраси та аксесуари для волосся",
      howToHeader: `<b>Як подати пропозицію?</b>Все просто! Заповніть форму на нашому
            сайті, і наш менеджер зв’яжеться з Вами протягом 5 робочих днів.`,
      advantagesHeader: "Переваги для партнерів",
      advantagesDesc:
        "Співпраця з мережею супермаркетів ТА-DA! відкриває для українських і міжнародних партнерів низку переваг:",
      listItem1: ` <b>Широкий ринок збуту:</b> Партнери отримують доступ до великої
              кількості покупців у різних регіонах України. Щомісяця мережа
              обслуговує сотні тисяч клієнтів, що робить ТА-DA! важливим каналом
              збуту продукції.`,
      listItem2: `<b>Можливості для спільного розвитку:</b> Партнерські програми
              включають взаємне просування, спільні акції, спільне навчання та
              обмін досвідом.`,
      listItem3: ` <b>Прозорі умови співпраці:</b> Компанія гарантує партнерам
              справедливі та прозорі умови співпраці, які ґрунтуються на
              відкритості, довірі та взаємній вигоді.`,
    },
    education: {
      title: "Навчання і підтримка",
      phrase0: "Консультації при виборі торгової площі під маркет.",
      phrase1: "Розрахунок інвестицій згідно з запропонованою площі.",
      phrase2:
        "Рекламна прив'язка згідно з міською концепції, планограмма розстановки обладнання супермаркету.",
      phrase3: "Формування товарного наповнення маркету.",
      phrase4:
        "Тренінг і навчання продавців-консультантів на діючих cупермаркетах мережі.",
      phrase5: "Виїзд фахівців на збірку і відкриття маркету за місцем.",
      phrase6:
        "Всебічне консультування і підтримка з ведення торговельної та операційної діяльності.",
      phrase7: "Консультації з питань мерчандайзингу.",
    },
    requirement: {
      title: "Вимоги до приміщення",
      phrase0: "Розташування в житлових масивах міста.",
      phrase1:
        "Тип приміщення: окремо розташована будівля або торгівельний центр.",
      phrase2:
        "Поверховість: бажано 1й поверх з окремим входом (виключення - торгові центри)",
      phrase3: "Наявність зони розвантаження.",
      phrase4: "Електрична потужність від 30-45кВт.",
      phrase5:
        "Наявність комунікацій (холодне водопостачання та водовідведення, інтернет).",
      phrase6:
        "Можливість розміщення узгодженої реклами відповідно до місцевих правил / концепціями на фасаді будівлі.",
      phrase7: "Площа торгового приміщення від 600 м.кв. до 1200 м.кв.",
    },
    contacts: {
      fee: "Паушальний внесок",
      feePhrase: "Разовий платіж за послуги та торгову марку",
      term: "Термін договору",
      termPhrase0: "Від 3-х років",
      termPhrase1: "З можливістю пролонгації кожні 3 роки.",
      royalty: "Роялті",
      royaltyPhtrase: "Відсоток від прибутку",
      name: "ПІБ",
      else: "Інше",
      proposition: "Комерційна пропозиція",
      franchise: "Франшиза",
      type: "Тип запиту",
      phone: "Телефон",
      field: "Введіть свою пропозицію сюди:",
      send: "Відправити",
      rights: "© 2024 Всі права захищені",
      net: "Мережа роздрібної торгівлі ТА-DA!",
      location: " м.Полтава,Україна",
    },
  },
  en: {
    header: {
      main: "Home",
      aboutUs: "About Us",
      whyWe: "Why Us",
      franchise: "About Franchise",
      contacts: "Contacts",
    },
    hero: {
      subtitle: "Daily Must-Haves for Your Home. Every day!",
      seeMore: "See more",
    },
    aboutUs: {
      title: "About Us",
      companyInfo: `TA-DA! retail chain was founded in 2000. Initially, the company specialized in wholesale trade. Since 2019, it has been developing its supermarket chain. By `,
      companyInfo2: ", TA-DA! has grown to over ",
      companyInfo3: " locations across ",
      companyInfo4:
        " cities in Ukraine, serving more than 450,000 customers each month. The total area of the stores varies from 600 m².",
      description:
        'The company\'s goal is to create supermarkets that make life easier for consumers, and its motto is: "TA-DA! Daily Must-Haves for Your Home. Every day".',
      description1:
        "The company actively promotes sports and has a clear social position aimed at developing society. It cultivates a corporate culture based on trust, responsibility, and transparency in communication.",
      description2:
        "TA-DA! retail chain offers a wide range of daily necessities, toys, goods for outdoor activities, fishing, gardening, stationery, and much more.",
      description3:
        "TA-DA! is known for its reasonable prices and ongoing promotions, which attract customers. The chain is also committed to supporting local manufacturers by offering Ukrainian-made products.",
      description4:
        "Since 2023, TA-DA! chain has been actively engaged in foreign economic activity to provide Ukrainian consumers with quality goods at affordable prices, interesting novelties, and a wide range of assortments.",
    },
    whyWe: {
      years: "years",
      yearsOnMarket: "Successfully operating in the market",
      cities: "cities",
      andMore: "Increasing every year",
      markets: "supermarkets",
      marketsInUa: "Across Ukraine",
      clients: "clients",
      perMonth: "Every month!",
      buyingOnline:
        "A wide range of products and affordable prices not only offline but also online!",
      goToSite: "Return to the main website",
    },
    franchise: {
      aboutFranchise: "About Franchise",
      advantages: "Advantages",
      advantParah: `We offer a wide and diverse range of products. The entire assortment is carefully selected by manufacturers and product quality. In the store, you can purchase various product groups such as household chemicals, cosmetics, construction chemicals, tableware for serving and cooking, cleaning supplies, small household appliances, textiles, children's goods, garden tools, seeds, fertilizers, and much more.`,
      financials: "Financial Parameters",
      financialParah:
        "The franchise fee depends on the area of the supermarket, averaging $55 per square meter. The budget for opening one store starts from $80,000. Monthly royalties start from the second month and are discussed individually. The estimated payback period is from 20 months. The contract is concluded for a period of 3 years with the possibility of extension. In case of contract violations, the franchise can be deactivated.",
    },
    education: {
      title: "Training and Support",
      phrase0: "Consultations on choosing a trading area for the store.",
      phrase1: "Calculation of investments according to the proposed area.",
      phrase2:
        "Advertising linkage according to the city concept, layout of supermarket equipment.",
      phrase3: "Formation of the store's product assortment.",
      phrase4:
        "Training and coaching of sales consultants in existing chain supermarkets.",
      phrase5: "Specialists` on-site visit to open of the stores.",
      phrase6:
        "Comprehensive consulting and support in conducting trading and operational activities.",
      phrase7: "Consultations on merchandising issues.",
    },
    requirement: {
      title: "Requirements for the Premises",
      phrase0: `<b>Location:</b> Should be situated in residential areas of the city.`,
      phrase1: `<b>Type of Premises:</b> Preferably a standalone building or a shopping center.`,
      phrase2: `<b>Floor:</b> Ideally the first floor with a separate entrance (exceptions only for shopping malls).`,
      phrase3: `<b>Discharge Zone:</b> Availability of a discharge zone.`,
      phrase4: `<b>Electrical Power:</b> From 30–45 kW.`,
      phrase5: `<b>Communications:</b> Availability of utilities (cold water supply and drainage, internet).`,
      phrase6: `<b>Advertising:</b> Possibility to place agreed advertising according to local rules/concepts on the building facade.`,
      phrase7: `<b>Area:</b> Trading area from 600 sq.m. to 1200 sq.m.`,
    },
    collaboration: {
      header: "COOPERATION",
      description1:
        "One of the key elements of the TA-DA! supermarket chain's development strategy is cooperation with Ukrainian and international manufacturers, suppliers, and partners. The company strives to provide its customers with quality products at affordable prices, focusing on trustworthiness, long-term partnerships and innovation.",
      header1: "Cooperations with Ukrainian manufacturers and suppliers",
      localBiznes:
        "TA-DA! pays great attention to cooperation with Ukrainian manufacturers, supporting local businesses, and contributing to the development of the national economy. The main focus is on:",
      localBiznesDesc1: `<b>Local products:</b> The chain actively cooperates with Ukrainian manufacturers of food, clothing, household goods, and other products, helping them penetrate the national market. It provides customers with fresh, high-quality products at competitive prices.`,
      localBiznesDesc2: `<b>Support of small and medium-sized businesses:</b> TA-DA! cooperates with both large national companies along with small and medium-sized manufacturers, allowing them to expand their business and increase sales. It fosters the development of entrepreneurial activity in Ukraine.`,
      localBiznesDesc3: `<b>Local brands and sustainability:</b> The company actively supports Ukrainian brands that focus on producing eco-friendly and organic products. It enables consumers to choose products that meet modern requirements for environmental safety and sustainable development.`,
      innovation:
        "TA-DA! also builds trusting relationships with Ukrainian suppliers, ensuring stable order volumes and transparent terms of cooperation. The main factors in selecting suppliers are product quality, on-time deliveries, competitive prices, and commitment to innovation.",
      internationalHeader:
        "Cooperations with international manufacturers and suppliers",
      internationalDesc1:
        "TA-DA! strives to provide its customers with diverse and high-quality goods by cooperating with international manufacturers and suppliers from different countries. Thanks to international cooperation, the chain can offer unique products with no analogs in the Ukrainian market and implement the best global retail practices.",
      internationalHeader1:
        "The main directions of international cooperation are:",
      internationalSides1: `<b>Import of high-quality products:</b> TA-DA! cooperates with foreign manufacturers of toys, tableware, household goods, and other categories to provide customers with a wide range of high-quality products at competitive prices. We are committed to selecting partners who adhere to international quality and safety standards.`,
      internationalSides2: `<b>Logistics and technology:</b> By partnering with international logistics companies, TA-DA! can streamline its supply chain, shorten delivery times, and improve logistics efficiency. Furthermore, collaborations with foreign technology providers enable the adoption of advanced tools for managing supply chains, automating processes, and overseeing store operations.`,
      selectionHeader:
        "The main factors for selecting products for our chain are the following:",
      selectionDesc1:
        "Product matches the company concept and is competitively priced.",
      selectionDesc2: "High demand among customers.",
      selectionDesc3:
        "Shelf life over 90 days without special storage requirements.",
      selectionDesc4: "Licensed and certified for trademark use.",
      goodsHeader: "The following product groups are of interest to us:",
      good1: "Children's toys and educational games",
      good2: "Household goods",
      good3: "Kitchen utensils",
      good4: "Housewares",
      good5: "Office supplies",
      good6: "Clothing",
      good7: "Bags, wallets, cosmetics",
      good8: "Jewelry and hair accessories",
      howToHeader: `<b>How to reach out to us?</b> It's simple! Fill out the form on our website, and our manager will contact you within 5 workdays.`,
      advantagesHeader: "Benefits for partners",
      advantagesDesc:
        "Cooperation with TA-DA! retail chain allows Ukrainian and international partners to gain a wide range of benefits:",
      listItem1: `<b>Wide sales market:</b> Partners get access to many customers in different regions of Ukraine. The chain serves hundreds of thousands of customers every month, making TA-DA! an essential sales channel.`,
      listItem2: `<b>Opportunities for co-development:</b> The partnership programs include mutual promotion, joint promotion, mutual training, and experience exchange.`,
      listItem3: `<b>Transparent terms of cooperation:</b> The company guarantees its partners fair and transparent cooperation based on openness, trust, and mutual profit.`,
    },

    contacts: {
      fee: "Franchise Fee",
      feePhrase: "One-time payment for services and trademark",
      term: "Contract Term",
      termPhrase0: "From 3 years",
      termPhrase1: "With the option for renewal every 3 years.",
      royalty: "Royalty",
      royaltyPhtrase: "Percentage of profit",
      name: "Full Name",
      else: "Other",
      proposition: "Commercial Offer",
      franchise: "Franchise",
      type: "Request Type",
      phone: "Phone number",
      field: "Enter your message here:",
      send: "Send",
      rights: "© 2024 All Rights Reserved",
      net: "Retail chain TA-DA!",
      location: "Poltava, Ukraine",
    },
  },
};

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}
var MD5 = function (d) {
  var r = M(V(Y(X(d), 8 * d.length)));
  return r.toLowerCase();
};
function M(d) {
  for (var _, m = "0123456789ABCDEF", f = "", r = 0; r < d.length; r++)
    (_ = d.charCodeAt(r)), (f += m.charAt((_ >>> 4) & 15) + m.charAt(15 & _));
  return f;
}
function X(d) {
  for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0;
  for (m = 0; m < 8 * d.length; m += 8)
    _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32;
  return _;
}
function V(d) {
  for (var _ = "", m = 0; m < 32 * d.length; m += 8)
    _ += String.fromCharCode((d[m >> 5] >>> m % 32) & 255);
  return _;
}
function Y(d, _) {
  (d[_ >> 5] |= 128 << _ % 32), (d[14 + (((_ + 64) >>> 9) << 4)] = _);
  for (
    var m = 1732584193, f = -271733879, r = -1732584194, i = 271733878, n = 0;
    n < d.length;
    n += 16
  ) {
    var h = m,
      t = f,
      g = r,
      e = i;
    (f = md5_ii(
      (f = md5_ii(
        (f = md5_ii(
          (f = md5_ii(
            (f = md5_hh(
              (f = md5_hh(
                (f = md5_hh(
                  (f = md5_hh(
                    (f = md5_gg(
                      (f = md5_gg(
                        (f = md5_gg(
                          (f = md5_gg(
                            (f = md5_ff(
                              (f = md5_ff(
                                (f = md5_ff(
                                  (f = md5_ff(
                                    f,
                                    (r = md5_ff(
                                      r,
                                      (i = md5_ff(
                                        i,
                                        (m = md5_ff(
                                          m,
                                          f,
                                          r,
                                          i,
                                          d[n + 0],
                                          7,
                                          -680876936
                                        )),
                                        f,
                                        r,
                                        d[n + 1],
                                        12,
                                        -389564586
                                      )),
                                      m,
                                      f,
                                      d[n + 2],
                                      17,
                                      606105819
                                    )),
                                    i,
                                    m,
                                    d[n + 3],
                                    22,
                                    -1044525330
                                  )),
                                  (r = md5_ff(
                                    r,
                                    (i = md5_ff(
                                      i,
                                      (m = md5_ff(
                                        m,
                                        f,
                                        r,
                                        i,
                                        d[n + 4],
                                        7,
                                        -176418897
                                      )),
                                      f,
                                      r,
                                      d[n + 5],
                                      12,
                                      1200080426
                                    )),
                                    m,
                                    f,
                                    d[n + 6],
                                    17,
                                    -1473231341
                                  )),
                                  i,
                                  m,
                                  d[n + 7],
                                  22,
                                  -45705983
                                )),
                                (r = md5_ff(
                                  r,
                                  (i = md5_ff(
                                    i,
                                    (m = md5_ff(
                                      m,
                                      f,
                                      r,
                                      i,
                                      d[n + 8],
                                      7,
                                      1770035416
                                    )),
                                    f,
                                    r,
                                    d[n + 9],
                                    12,
                                    -1958414417
                                  )),
                                  m,
                                  f,
                                  d[n + 10],
                                  17,
                                  -42063
                                )),
                                i,
                                m,
                                d[n + 11],
                                22,
                                -1990404162
                              )),
                              (r = md5_ff(
                                r,
                                (i = md5_ff(
                                  i,
                                  (m = md5_ff(
                                    m,
                                    f,
                                    r,
                                    i,
                                    d[n + 12],
                                    7,
                                    1804603682
                                  )),
                                  f,
                                  r,
                                  d[n + 13],
                                  12,
                                  -40341101
                                )),
                                m,
                                f,
                                d[n + 14],
                                17,
                                -1502002290
                              )),
                              i,
                              m,
                              d[n + 15],
                              22,
                              1236535329
                            )),
                            (r = md5_gg(
                              r,
                              (i = md5_gg(
                                i,
                                (m = md5_gg(
                                  m,
                                  f,
                                  r,
                                  i,
                                  d[n + 1],
                                  5,
                                  -165796510
                                )),
                                f,
                                r,
                                d[n + 6],
                                9,
                                -1069501632
                              )),
                              m,
                              f,
                              d[n + 11],
                              14,
                              643717713
                            )),
                            i,
                            m,
                            d[n + 0],
                            20,
                            -373897302
                          )),
                          (r = md5_gg(
                            r,
                            (i = md5_gg(
                              i,
                              (m = md5_gg(m, f, r, i, d[n + 5], 5, -701558691)),
                              f,
                              r,
                              d[n + 10],
                              9,
                              38016083
                            )),
                            m,
                            f,
                            d[n + 15],
                            14,
                            -660478335
                          )),
                          i,
                          m,
                          d[n + 4],
                          20,
                          -405537848
                        )),
                        (r = md5_gg(
                          r,
                          (i = md5_gg(
                            i,
                            (m = md5_gg(m, f, r, i, d[n + 9], 5, 568446438)),
                            f,
                            r,
                            d[n + 14],
                            9,
                            -1019803690
                          )),
                          m,
                          f,
                          d[n + 3],
                          14,
                          -187363961
                        )),
                        i,
                        m,
                        d[n + 8],
                        20,
                        1163531501
                      )),
                      (r = md5_gg(
                        r,
                        (i = md5_gg(
                          i,
                          (m = md5_gg(m, f, r, i, d[n + 13], 5, -1444681467)),
                          f,
                          r,
                          d[n + 2],
                          9,
                          -51403784
                        )),
                        m,
                        f,
                        d[n + 7],
                        14,
                        1735328473
                      )),
                      i,
                      m,
                      d[n + 12],
                      20,
                      -1926607734
                    )),
                    (r = md5_hh(
                      r,
                      (i = md5_hh(
                        i,
                        (m = md5_hh(m, f, r, i, d[n + 5], 4, -378558)),
                        f,
                        r,
                        d[n + 8],
                        11,
                        -2022574463
                      )),
                      m,
                      f,
                      d[n + 11],
                      16,
                      1839030562
                    )),
                    i,
                    m,
                    d[n + 14],
                    23,
                    -35309556
                  )),
                  (r = md5_hh(
                    r,
                    (i = md5_hh(
                      i,
                      (m = md5_hh(m, f, r, i, d[n + 1], 4, -1530992060)),
                      f,
                      r,
                      d[n + 4],
                      11,
                      1272893353
                    )),
                    m,
                    f,
                    d[n + 7],
                    16,
                    -155497632
                  )),
                  i,
                  m,
                  d[n + 10],
                  23,
                  -1094730640
                )),
                (r = md5_hh(
                  r,
                  (i = md5_hh(
                    i,
                    (m = md5_hh(m, f, r, i, d[n + 13], 4, 681279174)),
                    f,
                    r,
                    d[n + 0],
                    11,
                    -358537222
                  )),
                  m,
                  f,
                  d[n + 3],
                  16,
                  -722521979
                )),
                i,
                m,
                d[n + 6],
                23,
                76029189
              )),
              (r = md5_hh(
                r,
                (i = md5_hh(
                  i,
                  (m = md5_hh(m, f, r, i, d[n + 9], 4, -640364487)),
                  f,
                  r,
                  d[n + 12],
                  11,
                  -421815835
                )),
                m,
                f,
                d[n + 15],
                16,
                530742520
              )),
              i,
              m,
              d[n + 2],
              23,
              -995338651
            )),
            (r = md5_ii(
              r,
              (i = md5_ii(
                i,
                (m = md5_ii(m, f, r, i, d[n + 0], 6, -198630844)),
                f,
                r,
                d[n + 7],
                10,
                1126891415
              )),
              m,
              f,
              d[n + 14],
              15,
              -1416354905
            )),
            i,
            m,
            d[n + 5],
            21,
            -57434055
          )),
          (r = md5_ii(
            r,
            (i = md5_ii(
              i,
              (m = md5_ii(m, f, r, i, d[n + 12], 6, 1700485571)),
              f,
              r,
              d[n + 3],
              10,
              -1894986606
            )),
            m,
            f,
            d[n + 10],
            15,
            -1051523
          )),
          i,
          m,
          d[n + 1],
          21,
          -2054922799
        )),
        (r = md5_ii(
          r,
          (i = md5_ii(
            i,
            (m = md5_ii(m, f, r, i, d[n + 8], 6, 1873313359)),
            f,
            r,
            d[n + 15],
            10,
            -30611744
          )),
          m,
          f,
          d[n + 6],
          15,
          -1560198380
        )),
        i,
        m,
        d[n + 13],
        21,
        1309151649
      )),
      (r = md5_ii(
        r,
        (i = md5_ii(
          i,
          (m = md5_ii(m, f, r, i, d[n + 4], 6, -145523070)),
          f,
          r,
          d[n + 11],
          10,
          -1120210379
        )),
        m,
        f,
        d[n + 2],
        15,
        718787259
      )),
      i,
      m,
      d[n + 9],
      21,
      -343485551
    )),
      (m = safe_add(m, h)),
      (f = safe_add(f, t)),
      (r = safe_add(r, g)),
      (i = safe_add(i, e));
  }
  return Array(m, f, r, i);
}
function md5_cmn(d, _, m, f, r, i) {
  return safe_add(bit_rol(safe_add(safe_add(_, d), safe_add(f, i)), r), m);
}
function md5_ff(d, _, m, f, r, i, n) {
  return md5_cmn((_ & m) | (~_ & f), d, _, r, i, n);
}
function md5_gg(d, _, m, f, r, i, n) {
  return md5_cmn((_ & f) | (m & ~f), d, _, r, i, n);
}
function md5_hh(d, _, m, f, r, i, n) {
  return md5_cmn(_ ^ m ^ f, d, _, r, i, n);
}
function md5_ii(d, _, m, f, r, i, n) {
  return md5_cmn(m ^ (_ | ~f), d, _, r, i, n);
}
function safe_add(d, _) {
  var m = (65535 & d) + (65535 & _);
  return (((d >> 16) + (_ >> 16) + (m >> 16)) << 16) | (65535 & m);
}
function bit_rol(d, _) {
  return (d << _) | (d >>> (32 - _));
}
